import React from "react"
import styled from "styled-components"

import Seo from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"
import Form from "./Form"

const ContactPageWrapper = styled(PageWrapper)`
  padding-top: 5rem;

  color: ${props => props.theme.color.primary};
`
const NarrowRow = styled(Row)`
  margin-bottom: 3rem;
  max-width: 700px;

  text-align: center;

  a {
    text-decoration: underline;
  }
`

const ContactPage = () => (
  <ContactPageWrapper>
    <Seo title="Contact Us" />
    <NarrowRow>
      <h1>Contact Us</h1>

      <Form />
    </NarrowRow>
  </ContactPageWrapper>
)

export default ContactPage
